import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Scrollspy from "react-scrollspy"
import { Menu, X, AlignCenter } from "react-feather"

import { Container } from "../../global"
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
} from "./style"

import apocha_logo from "../../../images/apocha_icon_032.png"

const NAV_ITEMS = [""]

export default class Navigation extends Component {
  state = {
    mobileMenuOpen: false,
    hasScrolled: false,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 32) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }))
  }

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false })
    }
  }

  getNavLink = item => (
    <NavLink to={`/${item.toLowerCase()}`} onClick={this.closeMobileMenu}>
      {item}
    </NavLink>
  )

  getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map(item => item.toLowerCase())}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.map(navItem => (
          <NavItem key={navItem}>{this.getNavLink(navItem)}</NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  )

  render() {
    const { mobileMenuOpen } = this.state

    return (
      <Nav {...this.props} scrolled={this.state.hasScrolled}>
        <StyledContainer>
          <Brand>
            <Scrollspy offset={-64} item={["top"]} currentClassName="active">
              <a href="/" onClick={this.closeMobileMenu}>
                <div style={{display: 'flex', fontFamily: 'Righteous'}}>
                  <img style={{verticalAlign: AlignCenter}} src={apocha_logo} alt="apocha Logo"></img>&nbsp;<span>APOCHA</span>
                </div>
              </a>
            </Scrollspy>
          </Brand>
          <Mobile>
            <button
              onClick={this.toggleMobileMenu}
              style={{ color: "black", background: "none" }}
            >
              {this.state.mobileMenuOpen ? (
                <X size={24} alt="close menu" />
              ) : (
                <Menu size={24} alt="open menu" />
              )}
            </button>
          </Mobile>

          <Mobile hide>{this.getNavList({})}</Mobile>
        </StyledContainer>
        <Mobile>
          <NavContainer>
            {mobileMenuOpen && (
              <MobileMenu>
                <Container>{this.getNavList({ mobile: true })}</Container>
              </MobileMenu>
            )}
          </NavContainer>
        </Mobile>
      </Nav>
    )
  }
}

const NavContainer = styled(Container)
`
  background-color: #fffffff0;
`

const NavLink = styled(Link)`
  color: ${props => props.theme.color.primary};
  padding-bottom: 1px;
  margin-left: 16px;
  text-decoration: none;
`

